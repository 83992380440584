import React from 'react'
import { Container } from '../style/Version.styled'

export default function NavBarVersion() {
  return (
    <Container>
      <div className="version">
        &copy; TitleTab 2024 October 07 | &nbsp;
        <a href="https://t.me/titletab" target="_blank" rel="noreferrer">
          Support
        </a>
        &nbsp;&nbsp;
      </div>
    </Container>
  )
}
